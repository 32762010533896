<template>
  <div v-shortkey="shortkeys" @shortkey="triggerShortkey">
    <div class="row">
      <div class="form-group d-flex justify-content-start align-items-center col">
        <label for="specimenOrder"><b>Specimen:</b></label>
        <text-input
          class="small-input"
          v-model="specimen.specimenOrder"
          type="text"
          :noLabel="true"
          name="specimenOrder"
          id="specimenOrder"
          maxLength="3"
          :validator="$v.specimen.specimenOrder"
        />
      </div>
      <div class="form-group d-flex justify-content-end align-items-center col">
        <button
          v-if="[4, 6].includes(caseEditType)"
          class="btn btn-primary mr-2"
          @click.prevent="toggleChangeBlockCount"
        >
          Change{{ !value.protocolId ? " block count" : "" }}
        </button>
        <label v-if="!!specimen.id && !!value.protocolId" for="blocks"><b>Block(s):</b></label>
        <input
          v-if="!!specimen.id && !!value.protocolId"
          v-model="specimen.numberOfBlocks"
          type="number"
          :noLabel="true"
          :max="1000"
          :step="1"
          class="small-input form-control"
          id="blocks"
          name="blocks"
        />
        <button
          v-if="specimen.numberOfBlocks > 0"
          class="btn btn-primary ml-2"
          @click="togglePiecesPopup"
        >
          Pi<u>e</u>ces
        </button>
        <button
          v-if="specimen.numberOfBlocks > 0"
          :disabled="isLoading"
          class="btn btn-primary ml-2"
          @click="printBlocks"
        >
          P<u>r</u>int
        </button>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-4">
        <select-input
          v-model="specimen.protocolId"
          label="Protocol"
          id="protocol"
          name="protocol"
          ref="protocol"
          displayExpr="displayName"
          :items="protocolItems"
          accessKey="p"
          :validator="$v.specimen.protocolId"
          searchMode="startswith"
          :disabled="isProstateProtocol"
        />
      </div>
      <div class="form-group col-4">
        <label for="site" id="siteLabel"><b>S<u>i</u>te</b></label>
        <text-input
          v-model="specimen.site"
          :validator="$v.specimen.site"
          id="site"
          name="site"
          ref="site"
          maxLength="301"
          :forceUpperCase="upperCaseSite"
        />
      </div>
      <div class="form-group col-4">
        <select-input
          v-model="specimen.bodyPartId"
          id="bodyPartId"
          :dataSource="bodyPartsSorted"
          name="bodyPartId"
          label="Body Part"
        />
      </div>
    </div>
    <div class="row">
      <div :class="`form-group col-${AdditionalFields ? '3' : '4'} icd-col`">
        <text-input
          label="Clinical ICD10"
          maxLength="81"
          id="clinicalICDCode"
          name="clinicalICDCode"
          :validator="$v.specimen.clinicalICDCode"
          v-model="specimen.clinicalICDCode"
          :forceUpperCase="upperCaseClinicalIcd10"
        />
      </div>
      <div v-if="AdditionalFields" class="form-group col">
        <user
          label="Grosser"
          name="grosser"
          :allow-empty="false"
          :multiple="false"
          v-model="grosser"
        />
      </div>
      <div v-if="AdditionalFields" class="form-group col">
        <user
          name="cutter"
          label="Cutter"
          :allow-empty="false"
          :multiple="false"
          v-model="cutter"
          :close-on-select="true"
        />
      </div>
      <div :class="`form-group col-${AdditionalFields ? '3' : '4'}`">
        <text-input
          label="<u>M</u>easurement"
          id="measurement"
          name="measurement"
          ref="measurement"
          v-model="specimen.measurement"
        />
      </div>
    </div>
    <!-- <MacroEnabledEditor
      :class="{ 'text-uppercase': upperCaseClinical }"
      id="clinicalNotes"
      name="clinical"
      ref="clinical"
      accessKey="c"
      v-model="specimen.clinical"
    />
    <MacroEnabledEditor
      :class="{ 'text-uppercase': upperCaseGross }"
      id="gross"
      name="gross"
      accessKey="g"
      ref="gross"
      v-model="specimen.gross"
    />
    <MacroEnabledEditor
      :class="{ 'text-uppercase': upperCaseCaseNote }"
      id="caseNotes"
      name="caseNotes"
      label="Case Notes"
      accessKey="n"
      ref="caseNotes"
      v-model="specimen.caseNotes"
    /> -->
    <div id="editors" ref="editors" :class="editorClasses">
      <draggable
        v-model="draggableEditors"
        :disabled="!isUnlocked"
        :class="{ unlocked: isUnlocked }"
      >
        <MacroEnabledEditor
          v-for="editor in availableEditors"
          :id="editor.name"
          :key="editor.name"
          :ref="editor.name"
          v-bind="editor"
          v-model="specimen[editor.name]"
          @editorReady="handleAutoOpenEditor"
        />
      </draggable>
    </div>
    <div class="d-flex align-items-center justify-content-between mt-3">
      <div>
        <button v-if="isFrozenEnabled" class="btn btn-primary" @click="toggleFrozenForm">
          Fr<u>o</u>zen
        </button>
      </div>
      <div>
        <loader v-if="isLoading" size="small" />
        <button
          v-if="permissions.CaseSpecimenDelete"
          :disabled="formDisabled"
          @click="deleteSpecimen(specimen)"
          type="button"
          class="btn btn-danger"
        >
          Delete
        </button>
        <button
          @click="saveSpecimen(specimen)"
          type="button"
          class="btn btn-primary ml-2"
          :disabled="formDisabled"
          id="saveBtn"
        >
          Save
        </button>
      </div>
    </div>
    <modal :status="isMacroDialogOpen" @close="isMacroDialogOpen = false">
      <macro-popup
        @close="isMacroDialogOpen = false"
        :targetType="macroDialogType"
        :targetSpecimen="specimen"
        @macroSelected="macroDialogCallback"
        :dialogFromWysiwyg="macroFromWysiwyg"
      />
    </modal>
    <modal :status="isChangeBlockCountOpen" @close="toggleChangeBlockCount">
      <ChangeBlockCount
        :specimenId="specimen.id"
        :originalBlockCount="specimen.numberOfBlocks"
        @close="toggleChangeBlockCount"
        @changeBlockCount="handleChangeBlockCount"
      />
    </modal>
    <modal :status="isPiecesPopupOpen" @close="togglePiecesPopup" minWidth="100px">
      <PiecesPopup
        @cancel="togglePiecesPopup"
        :cassettes="specimen.cassettes"
        @submitPieces="submitPieces"
      />
    </modal>
    <modal :status="isFrozenOpen" @close="toggleFrozenForm">
      <FrozenForm
        @cancel="toggleFrozenForm"
        @submit="handleSubmitFrozenData"
        :formDisabled="formDisabled"
      />
    </modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import UsersAPI from "@/services/users";
import { helpers, maxLength } from "vuelidate/lib/validators";
import MacroEnabledEditor from "@/components/common/MacroEnabledEditor";
import { User } from "../Selectors";
import eventBus, {
  DISPATCH_NUMBER_OF_BLOCKS,
  fromBusEvent,
  OPEN_MACRO_POPUP,
  REQUEST_NUMBER_OF_BLOCKS,
  RESTORE_EDITOR_POSITION,
  SAVE_FROM_CASE_HEADER
} from "../../../modules/eventBus";
import { filter, switchMap, tap } from "rxjs/operators";
import {
  CassettesApi,
  DropdownApi,
  MacrosApi,
  PrintersApi,
  ReportsApi,
  CasesApi
} from "../../../services";
import TextInput from "@/components/common/TextInput.vue";
import SelectInput from "@/components/common/SelectInput.vue";
import { cloneDeep } from "lodash";
import Modal from "@/components/common/Modal.vue";
import MacroPopup from "@/components/MacroPopup.vue";
import { throwError } from "rxjs";
import Loader from "@/components/common/Loader.vue";
import { mergeSpecimensWithMacros } from "@/modules/mergeSpecimensWithMacros";
import physicianPopup from "@/mixins/physicianPopup.js";
import DataSource from "devextreme/data/data_source";
import draggable from "vuedraggable";
import { sortBy } from "lodash";
import { initialEditors } from "@/store/ApplicationSettings";
import {
  CaseEditTypeEnum,
  CaseStatusEnum,
  SpecimenFocusEnum,
  SpecimenFieldRequiredEnum
} from "@/modules/enums";
import {
  getAltKeys,
  getProstateSites,
  isModalOpen,
  removeExtraDivs,
  getBinMapFileDrop,
  getTextFromHtml,
  bitEnumToArray
} from "@/modules/helpers";
import ChangeBlockCount from "@/components/ChangeBlockCount.vue";
import { handleErrors } from "@/modules/handleErrors";
import PiecesPopup from "@/components/PiecesPopup.vue";
import { printFileDrop } from "@/modules/printFileDrop";
import FrozenForm from "@/components/FrozenForm.vue";

export default {
  name: "SpecimenForm",
  components: {
    MacroEnabledEditor,
    User,
    TextInput,
    SelectInput,
    Modal,
    MacroPopup,
    Loader,
    draggable,
    ChangeBlockCount,
    PiecesPopup,
    FrozenForm
  },
  mixins: [physicianPopup],
  props: {
    value: {
      type: Object
    },
    bodyParts: Array,
    protocolItems: Array,
    isUnlocked: Boolean,
    fieldToOpen: String
  },
  validations() {
    return {
      specimen: {
        site: {
          maxLength: maxLength(300),
          required: value => (this.casePrefix?.siteRequired ? helpers.req(value) : true)
        },
        clinicalICDCode: {
          maxLength: maxLength(80)
        },
        protocolId: {
          required: value => (this.casePrefix?.isProtocolRequired ? helpers.req(value) : true)
        },
        specimenOrder: {
          required: true
        }
      }
    };
  },
  data: () => ({
    userSearch: UsersAPI.searchStore,
    grosser: null,
    cutter: null,
    isMacroDialogOpen: false,
    isLoading: false,
    macroDialogType: 0,
    macroFromWysiwyg: false,
    specimen: {},
    isChangeBlockCountOpen: false,
    isModalOpen: false,
    isPiecesPopupOpen: false,
    shortkeys: getAltKeys("cegimnoprs"),
    isFrozenOpen: false
  }),
  mounted() {
    eventBus.$on(SAVE_FROM_CASE_HEADER, () => this.saveSpecimen(this.specimen));
  },
  beforeDestroy() {
    eventBus.$off(SAVE_FROM_CASE_HEADER);
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      currentSpecimen: state => state.accessionStore.currentSpecimen,
      caseDetails: state => state.accessionStore.caseDetails,
      ForceUpperCaseGross: state => state.labSettings.ForceUpperCaseGross,
      ForceUpperCaseSite: state => state.labSettings.ForceUpperCaseSite,
      ForceUpperCaseClinical: state => state.labSettings.ForceUpperCaseClinical,
      ForceUpperCaseCaseNote: state => state.labSettings.ForceUpperCaseCaseNote,
      ForceUpperCaseClinicalICD10: state => state.labSettings.ForceUpperCaseClinicalICD10,
      AdditionalFields: state => state.labSettings.AdditionalFields,
      specimens: state => state.accessionStore.specimens,
      caseEditType: state => state.accessionStore.editType,
      textEditors: state => state.applicationSettings.textEditors || initialEditors,
      casePrefix: state => state.accessionStore.casePrefix,
      autoOpenEditors: state => state.applicationSettings.autoOpenEditors,
      specimenFocus: state => state.labSettings.SpecimenFocus,
      getSiteFromProtocolDescription: state => state.labSettings.GetSiteFromProtocolDescription,
      SpecimenNonDiagnosticChange: state => state.labSettings.SpecimenNonDiagnosticChange,
      currentLab: state => state.currentLab,
      DefaultQtyPiecesPerCassette: state => state.labSettings.DefaultQtyPiecesPerCassette,
      PrintSlidesOnSpecimenSave: state => state.labSettings.PrintSlidesOnSpecimenSave,
      defaultGlassSlidePrinter: state => state.applicationSettings.defaultGlassSlidePrinter,
      FileDropPrintingConfiguration: state => state.labSettings.FileDropPrintingConfiguration,
      defaultCassettePrinter: state => state.applicationSettings.defaultCassettePrinter,
      LabSettingDefaultCassettePrinter: state => state.labSettings.DefaultCassetteProcedurePrinter,
      LabBinPrintOrder: state => state.labSettings.LabBinPrintOrder,
      labSettings: state => state.labSettings,
      shouldPrintOrders: state => state.accessionStore.shouldPrintOrders
    }),
    ...mapGetters("accessionStore", ["isCaseEditable", "isReported"]),
    ...mapGetters(["permissions"]),
    editorClasses() {
      return {
        upperCaseClinical: parseInt(this.ForceUpperCaseClinical) || false,
        upperCaseCaseNote: parseInt(this.ForceUpperCaseCaseNote) || false,
        upperCaseGross: parseInt(this.ForceUpperCaseGross) || false
      };
    },
    availableEditors() {
      const { CaseFieldEditGross, CaseFieldEditCaseNotes, CaseFieldEditClinical } =
        this.permissions;

      return sortBy(
        [
          CaseFieldEditGross && { name: "gross", ...this.textEditors.gross },
          CaseFieldEditCaseNotes && {
            name: "caseNotes",
            ...this.textEditors.caseNotes,
            accessKey: "n"
          },
          CaseFieldEditClinical && { name: "clinical", ...this.textEditors.clinical }
        ],
        "specimenOrder"
      ).filter(value => value);
    },

    bodyPartsSorted() {
      return new DataSource({
        store: this.bodyParts,
        sort: ["displayName"]
      });
    },

    upperCaseGross() {
      if (Number(this.ForceUpperCaseGross)) {
        return true;
      }
      return false;
    },
    upperCaseCaseNote() {
      if (Number(this.ForceUpperCaseCaseNote)) {
        return true;
      }
      return false;
    },
    upperCaseSite() {
      if (Number(this.ForceUpperCaseSite)) {
        return true;
      }
      return false;
    },
    upperCaseClinical() {
      if (Number(this.ForceUpperCaseClinical)) {
        return true;
      }
      return false;
    },
    status() {
      return this.caseDetails.status;
    },
    formDisabled() {
      if (this.caseDetails.status === CaseStatusEnum.ReportedPrelim) {
        return !this.permissions.CaseSpecimenCreateEdit;
      }
      if (this.isReported) {
        if (this.isCaseEditable) {
          return (
            !this.SpecimenNonDiagnosticChange &&
            [CaseEditTypeEnum.NonDiagnostic, CaseEditTypeEnum.Billing].includes(this.caseEditType)
          );
        }
        return true;
      } else if (this.isCaseEditable) {
        return !this.permissions.CaseSpecimenCreateEdit;
      } else {
        return !this.isCaseEditable;
      }
    },
    draggableEditors: {
      get() {
        return this.availableEditors;
      },
      set(value) {
        const newEditors = value
          .map((editor, idx) => {
            return {
              ...editor,
              specimenOrder: idx
            };
          })
          .reduce((acc, curr) => {
            acc[curr.name] = curr;
            return acc;
          }, {});
        this.$store.commit("applicationSettings/setEditorsOrder", {
          ...this.textEditors,
          ...newEditors
        });
      }
    },
    isFrozenEnabled() {
      return this.permissions.CaseFieldEditFrozen;
    },
    isProstateProtocol() {
      if (this.specimen.protocolId) {
        const protocol = this.protocolItems.find(e => e.id === this.specimen.protocolId);
        if (protocol) {
          return protocol?.isProstate;
        }
      }
      return false;
    },
    upperCaseClinicalIcd10() {
      return this.ForceUpperCaseClinicalICD10;
    }
  },
  watch: {
    specimen: {
      deep: true,
      handler(nv) {
        this.$store.commit("accessionStore/setCurrentSpecimen", nv);
      }
    },
    grosser(nv) {
      if (nv.id != this.specimen.grosserUserId) {
        this.specimen.grosserUserId = nv.id;
      }
    },
    cutter(nv) {
      if (nv.id != this.specimen.cutterUserId) {
        this.specimen.cutterUserId = nv.id;
      }
    },
    "specimen.cutterUserId": {
      immediate: true,
      handler: function (nv) {
        if (nv && nv != this.cutter?.id) {
          this.userSearch.load({ filter: ["id", "=", nv] }).then(res => {
            this.cutter = res[0];
          });
        }
      }
    },
    "specimen.grosserUserId": {
      immediate: true,
      handler: function (nv) {
        if (nv && nv != this.grosser?.id) {
          this.userSearch.load({ filter: ["id", "=", nv] }).then(res => {
            this.grosser = res[0];
          });
        }
      }
    },
    "specimen.specimenOrder": {
      immediate: true,
      handler: function (nv) {
        if (nv && /[a-z]/i.test(nv)) {
          this.specimen.specimenOrder = nv.toUpperCase();
        }
      }
    },
    "value.specimenOrder": {
      immediate: true,
      handler() {
        this.specimen = cloneDeep(this.value);
        if (this.isProstateProtocol && !this.value.site) {
          this.getSiteForProstateSpecimen();
        }
      }
    },
    "specimen.protocolId": {
      handler(nv) {
        if (!this.specimen.site && this.getSiteFromProtocolDescription && nv) {
          const protocol = this.protocolItems.find(e => e.id === nv);
          if (protocol?.protocolDescription) {
            this.specimen.site = protocol.protocolDescription;
          }
        }
      }
    },
    "specimen.cassettes": {
      immediate: true,
      handler(nv) {
        if (nv?.length !== this.specimen.numberOfBlocks) {
          this.specimen.numberOfBlocks = nv?.length || 0;
        }
      }
    },
    isProstateProtocol: {
      immediate: true,
      handler(nv) {
        if (nv && !this.specimen.site) {
          this.getSiteForProstateSpecimen();
        }
      }
    }
  },
  subscriptions() {
    const openMacroFromWysiwyg$ = fromBusEvent(OPEN_MACRO_POPUP).pipe(
      filter(() => !this.isFrozenOpen),
      tap(({ type }) => {
        this.macroDialogType = type;
        this.isMacroDialogOpen = true;
        this.macroFromWysiwyg = true;
      }),
      switchMap(({ callback }) => {
        return fromBusEvent(RESTORE_EDITOR_POSITION).pipe(
          tap(macros => {
            this.macroFromWysiwyg = false;
            this.macroDialogType = 0;
            this.isMacroDialogOpen = false;
            callback(macros);
            for (const macro of macros) {
              ["diagnosis", "microscopic", "notes", "caseNotes", "gross", "clinical"].forEach(
                property => {
                  if (macro[property]) {
                    this.$refs[property].expand();
                    if (property === "diagnosis") {
                      this.$refs[property].focus();
                      this.$refs[property].$el.scrollIntoView({
                        behavior: "auto",
                        block: "center",
                        inline: "center"
                      });
                    }
                  }
                }
              );
            }
          })
        );
      })
    );
    //Connects the block num field to the
    const changeBlockNum$ = fromBusEvent(DISPATCH_NUMBER_OF_BLOCKS).pipe(
      filter(data => this.value.id === data.id),
      tap(data => {
        this.numberOfBlocks_old = data.numberOfBlocks ?? this.numberOfBlocks_old;
        this.$emit("input", {
          ...this.value,
          protocolId: this.protocolId,
          numberOfBlocks: data.numberOfBlocks || this.value.numberOfBlocks,
          cassettes: data?.cassettes?.length ? data.cassettes : this.value?.cassettes
        });
      })
    );
    const blockNumber$ = this.$watchAsObservable("specimen.numberOfBlocks", { deep: true }).pipe(
      filter(({ newValue }) => {
        if (this.specimen.id) {
          const savedSpecimen = this.specimens.find(spec => spec.id === this.specimen.id);
          return Number(newValue) !== Number(savedSpecimen.numberOfBlocks);
        }
        return false;
      }),
      tap(async ({ newValue, oldValue }) => {
        if (newValue === 0) {
          const confirm = await window.confirm(
            "Setting number of blocks to 0 will remove all orders. <br/> Are you sure?"
          );
          if (!confirm) {
            return;
          }
        } else if (newValue < this.value.numberOfBlocks) {
          const confirm = await window.confirm(
            "Decreasing the number of blocks will remove associated orders. <br/> Are you sure?"
          );
          if (!confirm) {
            this.specimen.numberOfBlocks = oldValue;
          }
        }
        if (newValue !== this.specimen.cassettes.length) {
          this.updateCassettes();
        }
      })
    );
    const requestBlockNum$ = fromBusEvent(REQUEST_NUMBER_OF_BLOCKS).pipe(
      tap(() => {
        const numberOfBlocks = this.numberOfBlocks;
        eventBus.$emit(DISPATCH_NUMBER_OF_BLOCKS, {
          id: this.value.id,
          numberOfBlocks,
          cassettes: this.value.cassettes
        });
      })
    );
    const removeSpecimenProtocol$ = this.$watchAsObservable("specimen.protocolId").pipe(
      filter(({ newValue: newProtocolId }) => newProtocolId === null),
      tap(async ({ oldValue: oldProtocolId }) => {
        const confirm = await window.confirm(
          "Removing the protocol will result in removing all blocks & orders for this specimen. <br/> Are you sure?"
        );
        if (!confirm) {
          this.specimen.protocolId = oldProtocolId;
        } else {
          this.$store.commit(
            "accessionStore/setCaseSpecimens",
            this.specimens.map(specimen => {
              if (specimen.id === this.specimen.id) {
                specimen.numberOfBlocks = 0;
              }
              return specimen;
            })
          );
          this.specimen.numberOfBlocks = 0;
        }
      })
    );
    const specimenProtocol$ = this.$watchAsObservable("value", {
      immediate: true,
      deep: true
    }).pipe(
      switchMap(({ newValue: newSpecimen }) =>
        this.$watchAsObservable("specimen.protocolId").pipe(
          filter(({ newValue, oldValue }) => {
            return (
              newSpecimen.specimenOrder === this.currentSpecimen.specimenOrder &&
              oldValue !== newValue
            );
          }),
          switchMap(async ({ newValue, oldValue }) => {
            if (oldValue) {
              if (
                this.specimen.specimenOrder === this.value.specimenOrder &&
                this.specimen.protocolId &&
                oldValue !== newValue
              ) {
                const confirm = await window.confirm(
                  "You are about to override the existing Protocol.<br> All existing Orders will be removed from this specimen and replaced by the Orders from the newly selected Protocol."
                );
                if (!confirm) {
                  throwError("User cancelled action.");
                }
              }
              return [
                await MacrosApi.searchStore.byKey(newValue),
                await MacrosApi.searchStore.byKey(oldValue)
              ];
            } else {
              return [await MacrosApi.searchStore.byKey(newValue), null];
            }
          }),
          tap(([protocol, oldProtocol]) => {
            if (protocol) {
              if (!this.specimen.gross) {
                this.specimen.gross = protocol.gross;
              } else if (oldProtocol?.gross) {
                this.specimen.gross = this.specimen.gross.replace(
                  oldProtocol.gross,
                  protocol.gross
                );
              } else if (protocol.gross) {
                this.specimen.gross += " " + protocol.gross;
              }
              if (protocol.gross) {
                //Open up the gross wysiwyg
                this.$refs.gross.isExpanded = true;
              }
              //Calculate protocol blocks
              const numberOfBlocks = protocol.procedures.reduce((acc, curr) => {
                if (!acc.includes(curr.blockNum)) {
                  acc.push(curr.blockNum);
                }
                return acc;
              }, []).length;
              if (this.specimen.numberOfBlocks !== numberOfBlocks) {
                this.specimen.numberOfBlocks = numberOfBlocks;
              }
            }
          })
        )
      )
    );

    return {
      blockNumber$,
      changeBlockNum$,
      requestBlockNum$,
      specimenProtocol$,
      removeSpecimenProtocol$,
      openMacroFromWysiwyg$
    };
  },
  methods: {
    triggerShortkey(event) {
      if (isModalOpen()) {
        return;
      }
      switch (event.srcKey) {
        case "i":
          this.$refs.site.focus();
          break;
        case "p":
          this.$refs.protocol.focus();
          break;
        case "g":
          this.$refs.gross[0].focus();
          break;
        case "c":
          this.$refs.clinical[0].focus();
          break;
        case "n":
          this.$refs.caseNotes[0].focus();
          break;
        case "m":
          this.$refs.measurement.focus();
          break;
        case "e":
          this.togglePiecesPopup();
          break;
        case "r":
          this.printBlocks();
          break;
        case "s":
          this.saveSpecimen(this.specimen);
          break;
        case "o":
          if (this.isFrozenEnabled) {
            this.toggleFrozenForm();
          }
          break;
        default:
          break;
      }
    },
    async useMacroOnSpecimen(macros, name) {
      try {
        this.$store.commit("accessionStore/setLoading", true);
        if (name) {
          const [updateSpecimen] = await mergeSpecimensWithMacros(
            [this.specimen],
            macros,
            macros.map(macro => `.${macro.macroName}`).join("")
          );
          for (const prop in updateSpecimen) {
            if (prop !== name) {
              this.specimen[prop] = updateSpecimen[prop];
            }
          }
        }
        for (const macro of macros) {
          if (macro.holdCodes?.length) {
            macro.holdCodes.forEach(holdCodeId => {
              if (Object.hasOwnProperty.call(holdCodeId, "id")) {
                holdCodeId = holdCodeId.id;
              }
              this.$store.dispatch("accessionStore/upsertCaseQuickLink", {
                type: "H",
                tagId: holdCodeId,
                caseId: this.specimen.caseId,
                text: `Added by Macro: ${macro.macroName} on Specimen: ${this.specimen.specimenOrder}`
              });
            });
          }
          if (macro.icdCodes?.length && !this.specimen?.isICDModified) {
            await this.$store
              .dispatch("accessionStore/runIcdEngine", {
                specimen: this.specimen,
                macro
              })
              .then(specimen => {
                this.specimen.icdCodes = specimen.icdCodes;
              });
          }
        }
      } catch (e) {
        console.error("Error loading macro from dialog", e);
        window.notify("Error occured loading macro.", "error");
      } finally {
        this.$store.commit("accessionStore/setLoading", false);
      }
    },
    async macroDialogCallback(macros) {
      this.isMacroDialogOpen = false;
      const updatedSpecimens = await mergeSpecimensWithMacros(
        [this.specimen],
        macros,
        macros.map(macro => `.${macro.macroName}`).join("")
      );
      this.specimen = updatedSpecimens[0];
      await this.useMacroOnSpecimen(macros);
      ["clinical", "gross", "caseNotes", "notes", "microscopic", "diagnosis"].forEach(property => {
        if (this.specimen[property]) {
          this.$refs[property].isExpanded = true;
        }
      });
    },
    async deleteSpecimen(specimen) {
      const confirm = await window.confirm(
        `<span class='text-danger font-weight-bold' style='font-size: 16pt;'>This action is irreversible!<br> Are you sure you want to delete specimen ${specimen.specimenOrder}?</span>`
      );
      if (!confirm) {
        return;
      }
      this.$emit("delete");
      this.$store.dispatch("accessionStore/removeCaseSpecimen", specimen);
    },
    async saveSpecimen(specimen) {
      if (this.formDisabled) {
        return;
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.notify("Please verify your input and try again.", "warning");
        return;
      }

      if (specimen.numberOfBlocks && !specimen.protocolId) {
        window.alert("A protocol must be selected before increasing number of blocks.");
        return;
      }
      if (
        bitEnumToArray(this.labSettings.SpecimenClinicalRequired).includes(
          SpecimenFieldRequiredEnum.Result
        )
      ) {
        if (!getTextFromHtml(specimen.clinical)) {
          const confirm = await window.confirm(
            "This specimen does not have clinical text. Do you still wish to save?"
          );
          if (!confirm) {
            this.triggerShortkey({ srcKey: "c" });
            return;
          }
        }
      }
      if (
        bitEnumToArray(this.labSettings.SpecimenGrossRequired).includes(
          SpecimenFieldRequiredEnum.Result
        )
      ) {
        let isGrossed = Boolean(specimen?.gross);
        if (isGrossed && specimen?.protocolId) {
          const protocol = await MacrosApi.getMacroDetails(specimen.protocolId);
          if (
            protocol?.gross &&
            getTextFromHtml(protocol.gross) === getTextFromHtml(specimen.gross)
          ) {
            isGrossed = false;
          }
        }
        if (!isGrossed) {
          const confirm = await window.confirm(
            "This specimen has not been grossed. Do you still wish to save?"
          );
          if (!confirm) {
            this.triggerShortkey({ srcKey: "g" });
            return;
          }
        }
      }
      this.isLoading = true;
      try {
        const confirmStatus = await this.getPhysicianPopup(5, [specimen]); //Specimen Save
        if (!confirmStatus) {
          this.isLoading = false;
          window.notify("User cancelled action.", "error");
          return;
        }
        let payload;
        for (const editor of this.draggableEditors) {
          specimen[editor.name] = removeExtraDivs(specimen[editor.name]);
        }
        const grossedBeforeSave = Boolean(specimen?.firstGrossedOn);
        if (specimen.id) {
          payload = await this.$store.dispatch("accessionStore/updateCaseSpecimen", specimen);
        } else {
          payload = await this.$store.dispatch("accessionStore/insertCaseSpecimen", specimen);
        }
        if (this.$refs.protocol.isFocused) {
          this.$refs.protocol.focusOut();
        }
        this.$emit("save", payload);
        if (this.PrintSlidesOnSpecimenSave && !grossedBeforeSave && payload[0]?.firstGrossedOn) {
          this.printSlides(payload);
        }
      } catch (error) {
        window.notify("Error saving specimen", "error");
      }
      this.isLoading = false;
    },
    displayUserName(user) {
      if (user.firstName && user.lastName) {
        return `${user.lastName}, ${user.firstName}`;
      }
      return user.username;
    },
    handleFocus() {
      this.$nextTick(() => {
        if (this.fieldToOpen) {
          this.triggerShortkey({ srcKey: this.fieldToOpen });
          return;
        }
        const specimenFocus = parseInt(this.specimenFocus) || null;
        let refToFocus = null;
        let shouldFocus = false;
        switch (specimenFocus) {
          case SpecimenFocusEnum.Protocol:
          case null:
            refToFocus = "protocol";
            shouldFocus = Boolean(!this.specimen.protocolId);
            break;
          case SpecimenFocusEnum.Site:
            refToFocus = "site";
            shouldFocus = true;
            break;
          case SpecimenFocusEnum.Measurement:
            refToFocus = "measurement";
            shouldFocus = true;
            break;
        }
        if (this.$refs[refToFocus]?.focus && shouldFocus) {
          this.$refs[refToFocus].focus();
        } else if (this.availableEditors?.length > 0) {
          this.focusFirstEditor();
        }
      });
    },
    toggleChangeBlockCount() {
      this.isChangeBlockCountOpen = !this.isChangeBlockCountOpen;
    },
    focusFirstEditor() {
      const firstEditorName = this.availableEditors[0].name;
      const firstEditorRef = this.$refs[firstEditorName][0] || this.$refs[firstEditorName];
      if (firstEditorRef) {
        firstEditorRef.focus();
      }
    },
    async handleChangeBlockCount(newBlockCount) {
      try {
        const payload = await this.$store.dispatch("accessionStore/updateCaseSpecimen", {
          ...this.specimen,
          numberOfBlocks: newBlockCount
        });
        this.$emit("save", payload);
      } catch (error) {
        handleErrors(error);
      }
    },
    togglePiecesPopup() {
      this.isPiecesPopupOpen = !this.isPiecesPopupOpen;
    },
    submitPieces(cassettes) {
      this.specimen.cassettes = cassettes;
      this.togglePiecesPopup();
      window.notify("Updated piece counts for blocks.");
    },
    updateCassettes() {
      const nv = this.specimen.numberOfBlocks;
      const ov = this.specimen.cassettes.length;
      const diff = nv - ov;
      if (diff > 0) {
        const defaultPieces = this.DefaultQtyPiecesPerCassette || 1;
        for (let i = 0; i < diff; i++) {
          this.specimen.cassettes.push({ blockNum: ov + i + 1, qtyPieces: defaultPieces });
        }
      }
      if (diff < 0) {
        this.specimen.cassettes = this.specimen.cassettes.slice(0, nv);
      }
    },
    async printSlides(data) {
      const specimen = data[0];
      const slideList = await ReportsApi.slideListStore.load({
        filter: [["specimenId", "=", specimen.id], "and", ["slidePrinted", "=", false]]
      });
      if (slideList.length) {
        const slidePrinter = this.defaultGlassSlidePrinter;
        if (!slidePrinter) {
          window.notify("Slides failed to print due to no default slide printer found.", "error");
          return;
        }
        if (this.labSettings.SlidePrintingUsesBatching) {
          await ReportsApi.printGlassSlidesBatch({
            printerId: slidePrinter,
            labId: this.currentLab,
            slideIds: sortBy(slideList, ["specimenOrder", "blockNum", "slideLabelNumber"]).map(
              e => e.slideId
            )
          });
        } else {
          for (const slide of slideList) {
            await ReportsApi.printGlassSlideLabels({
              slideId: slide.slideId,
              caseId: this.caseDetails.caseId,
              specimenId: slide.specimenId,
              numberOfCopies: 1,
              printerId: slidePrinter
            }).catch(() => {
              window.notify("Error printing slide labels.", "warning");
            });
          }
        }
        window.notify(`Printed ${slideList.length} slide(s).`);
      }
    },
    async printBlocks() {
      if (this.isLoading) {
        return;
      }
      const cassettePrinter = this.defaultCassettePrinter ?? this.LabSettingDefaultCassettePrinter;
      const fileDropSettings = JSON.parse(this.FileDropPrintingConfiguration);
      if (!cassettePrinter && !fileDropSettings) {
        window.notify("Could not print blocks due to no cassette printer found.", "error");
        return;
      }
      try {
        this.isLoading = true;
        let cassettesWithoutIds = false;
        for (const cassette of this.specimen.cassettes) {
          if (!cassette?.id) {
            cassettesWithoutIds = true;
            break;
          }
        }
        if (cassettesWithoutIds) {
          await this.$store.dispatch("accessionStore/increaseSpecimenCassetteQty", this.specimen);
          this.specimen.cassettes = this.currentSpecimen.cassettes;
        }
        const protocolBinMaps = new Set();
        const protocols = await DropdownApi.getProtocol(this.caseDetails.labPrefix);
        if (
          fileDropSettings &&
          (!cassettePrinter ||
            cassettePrinter === parseInt(process.env.VUE_APP_FILE_DROP_PRINTER_ID))
        ) {
          const defaultFileDropTray = fileDropSettings?.defaultTray;
          const labBinMaps = await PrintersApi.getLabBinMaps();
          const allCassettes = await ReportsApi.cassetteListStore.load({
            filter: [
              ["caseId", this.caseDetails.caseId],
              "and",
              ["specimenId", "=", this.specimen.id],
              "and",
              ["cassettePrinted", "=", false]
            ],
            sort: [
              { selector: "specimenOrder", desc: false },
              { selector: "blockNum", desc: false }
            ]
          });
          let payload = [];
          const cases = await CasesApi.getFileDropPrintInfo([this.caseDetails.caseId]);
          const { casePriorityBinMap, contactBinMap } = cases[0];
          for (const cassette of allCassettes) {
            let trayName = defaultFileDropTray;
            const targetProtocol = protocols.find(e => e.id === this.specimen?.protocolId);
            const binMapTray = getBinMapFileDrop({
              protocol: targetProtocol,
              labBinMaps,
              prefix: this.casePrefix,
              priorityBinMapId: casePriorityBinMap,
              contactBinMapId: contactBinMap
            });
            if (binMapTray) {
              trayName = binMapTray;
            }
            payload.push({
              ...cassette,
              cassetteTrayName: trayName,
              patientFirstName: this.caseDetails.patientFirstName,
              patientLastName: this.caseDetails.patientLastName
            });
          }
          await printFileDrop(payload);
          window.notify(`Printed ${payload.length} cassette label(s).`);
          const cassettesToMark = payload.map(e => e.cassetteId);
          await CassettesApi.markCassettesAsPrinted(cassettesToMark);
        } else if (cassettePrinter) {
          if (this.specimen.protocolId) {
            const { protocolId } = this.specimen;
            const targetProtocol = protocols.find(e => e.id === protocolId);
            if (targetProtocol?.binMapId) {
              protocolBinMaps.add(targetProtocol.binMapId);
            }
          }
          const labBinMapOrder = this.LabBinPrintOrder;
          const isProtocolTarget =
            typeof labBinMapOrder === "string" &&
            labBinMapOrder?.indexOf &&
            labBinMapOrder?.indexOf("protocol") === 0;
          // *** (1) If the labBinOrder has the protocol first.
          // *** (2) And if we found atleast 1 bin map on the specimen list.
          // *** (3) Then we will have to dispatch the prints one by one.

          const usesBatch = this.labSettings.CassettePrintingUsesBatching;
          if (protocolBinMaps.size > 1 || (isProtocolTarget && protocolBinMaps.size > 0)) {
            // *** ticket/IP-285
            // *** Request the latest cassettes by caseId from the database to print them 1 by 1.

            const allCassettes = await ReportsApi.cassetteListStore.load({
              filter: [
                ["caseId", this.caseDetails.caseId],
                "and",
                ["specimenId", "=", this.specimen.id],
                "and",
                ["cassettePrinted", "=", false]
              ],
              sort: [
                { selector: "specimenOrder", desc: false },
                { selector: "blockNum", desc: false }
              ]
            });
            if (usesBatch) {
              await ReportsApi.printBlockLabelsBatch({
                printerId: cassettePrinter,
                labId: this.currentLab,
                cassetteIds: allCassettes.map(e => e.cassetteId)
              });
            } else {
              for (const cassette of allCassettes) {
                // ! Protocol BinMaps are dependent on sending the specimen Id!
                await ReportsApi.printBlockLabels({
                  printerId: cassettePrinter,
                  labId: this.currentLab,
                  numberOfCopies: 1,
                  specimenId: cassette.specimenId,
                  cassetteId: cassette.cassetteId,
                  caseId: this.caseDetails.caseId
                });
              }
            }
            window.notify(`Printed ${allCassettes.length} cassette label(s).`);
          } else {
            // *** In this scenario, we can print all the cassettes at once but protocol binmaps will be ignored..
            if (usesBatch) {
              const allCassettes = await ReportsApi.cassetteListStore.load({
                filter: [
                  ["caseId", this.caseDetails.caseId],
                  "and",
                  ["specimenId", "=", this.specimen.id],
                  "and",
                  ["cassettePrinted", "=", false]
                ],
                sort: [
                  { selector: "specimenOrder", desc: false },
                  { selector: "blockNum", desc: false }
                ]
              });
              await ReportsApi.printBlockLabelsBatch({
                printerId: cassettePrinter,
                labId: this.currentLab,
                cassetteIds: allCassettes.map(e => e.cassetteId)
              });
            } else {
              await ReportsApi.printBlockLabels({
                printerId: cassettePrinter,
                labId: this.currentLab,
                numberOfCopies: 1,
                caseId: this.caseDetails.caseId
              });
            }
            window.notify(`Printed all cassette label(s).`);
          }
        }
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },
    toggleFrozenForm() {
      this.isFrozenOpen = !this.isFrozenOpen;
    },
    handleSubmitFrozenData(data) {
      window.notify("Specimen updated");
      this.specimen = { ...this.specimen, ...data };
      this.toggleFrozenForm();
    },
    handleAutoOpenEditor(editorName) {
      if (this.autoOpenEditors && this.$refs[editorName][0]?.expand) {
        this.$refs[editorName][0].expand();
      }
    },
    getSiteForProstateSpecimen() {
      const specimensWithSite = getProstateSites(this.specimens);
      const index = specimensWithSite
        .map(e => e.specimenOrder)
        .indexOf(this.specimen.specimenOrder);
      this.specimen.site = specimensWithSite[index].site;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .text-uppercase {
  input {
    text-transform: uppercase;
  }
}

::v-deep.unlocked {
  &,
  *,
  * > *,
  * {
    cursor: move !important;
  }
}
</style>
